<template>
  <div class="space-y-6">
    <h1 class="font-semibold text-3xl">COVID-19 Response</h1>
    <p class="font-semibold">
      We’re Taking Care of Your Donors During COVID-19 😷
    </p>
    <p class="italic">Last Updated: July 19, 2021</p>
    <p>
      As a result of Covid-19, we have been able to employ the following
      guidelines to ensure that package holders always receive value from their
      generous donations.
    </p>
    <p class="font-semibold">Packages won in 2019</p>
    <p>
      These packages have been extended by 6 months, free of charge, to a full
      24 months from their original issue date and have been provided access to
      the alternative destinations available in the Stay Safer Close to Home
      program.
    </p>
    <p class="font-semibold">Packages won after January 1, 2020</p>
    <p>
      These packages have been made available for travel for 2 years from their
      original issue date and have been provided access to the alternative
      destinations available in the Stay Safer Close to Home program.
    </p>
    <p class="font-semibold">Packages won after September 1, 2020</p>
    <p>
      These packages have been made available for travel for 3 years from their
      original issue date and have been provided access to the alternative
      destinations available in the Stay Safer Close to Home program.
    </p>
    <p>
      We have also provided Alternative Destinations under our
      <strong>Stay Safer Close to Home program.</strong> These destinations can
      be viewed when travelers log in and click
      <strong>“Browse Travel Accommodations”</strong> to view the available
      inventory for their package.
    </p>
    <p>If you have any questions, please don't hesitate to contact us.</p>
    <p>Thank you.</p>
  </div>
</template>